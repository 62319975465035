<template>
  <div>
    <base-homepage color="bg-teal-400"> </base-homepage>
  </div>
</template>

<script>
import BaseHomepage from '../components/homepage/BaseHomepage.vue';

export default {
  name: 'Home',
  components: {
    BaseHomepage
  }
};
</script>

<style></style>
